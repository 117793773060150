import { ofetch } from 'ofetch'
import { useAlertStore } from '~/stores/alert'
import { useAuthStore } from '~/stores/auth'
import { useSentry } from '~/composables/useSentry'

const config = useRuntimeConfig()
const defaultRepeat = 3

const custOfetch = async (url: string, options: any, repeat: number = defaultRepeat) => {
  const { logout } = useAuth()
  const alertStore = useAlertStore()
  const authStore = useAuthStore()
  const { captureException } = useSentry()

  const apiFetch = ofetch.create({
    headers: options.headers,
    async onResponseError({ response }) {
      // console.log(response, String(response))

      if (response.status === 401) {
        try {
          console.log(response.message)
          // console.log('Trying to refresh token')

          await $fetch(`${config.public.apiBase}/auth/refresh`, {
            method: 'GET',
            credentials: 'include',
          })

          // console.log('Token was refreshed!')
          // window.location.reload()
        }
        catch (e: any) {
          captureException(e)
          // console.log('Failed to refresh token', e.status, e)

          if (e.status === 401 && authStore.user) {
            alertStore.setAlert('Your session has expired. Please log in again.', 'error')
            logout()
          }
        }
      }
    },
  })
  return await apiFetch(url, { ...options, repeat })
}

export default custOfetch
