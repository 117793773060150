import { useAuthStore } from '~~/stores/auth'
import custOFetch from '~~/composables/useFetch'
import type { User } from '~/types/prisma'
const config = useRuntimeConfig()

export const useAuth = () => {
  const authStore = useAuthStore()
  const router = useRouter()
  const route = useRoute()
  const { captureException } = useSentry()

  const setUser = (user: User | null) => {
    authStore.setUser(user)
    return user
  }

  const login = async (
    email: string,
    password: string,
  ) => {
    const data: any = await custOFetch(`${config.public.apiBase}/auth/signin`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      body: {
        email,
        password,
      },
    })
    return data
  }

  const verify = async (
    verificationCode: string,
  ) => {
    const data: any = await custOFetch(`${config.public.apiBase}/auth/email/verify/${verificationCode}`, {
      method: 'GET',
      credentials: 'include',
    })
    return authStore.user
  }

  const signup = async (
    email: string,
    firstName: string,
    lastName: string,
    password: string,
  ) => {
    console.log(email)
    const data: any = await $fetch(`${config.public.apiBase}/auth/signup`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      body: {
        email,
        firstName,
        lastName,
        password,
      },
    })
    return authStore.user
  }

  const invite_signup = async (
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    phone: string,
    token: string,
  ) => {
    const data: any = await custOFetch(`${config.public.apiBase}/auth/invite-signup`, {
      method: 'POST',
      credentials: 'include',
      body: {
        email,
        firstName,
        lastName,
        password,
        phone,
        token,
      },
    })
    console.log('data from signup', data)
    return authStore.user
  }

  const leader_signup = async (
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    phone: string,
    token: string,
  ) => {
    const data: any = await custOFetch(`${config.public.apiBase}/auth/leader-signup`, {
      method: 'POST',
      credentials: 'include',
      body: {
        email,
        firstName,
        lastName,
        password,
        phone,
        token,
      },
    })
    console.log('data from signup', data)
    return authStore.user
  }

  const judge_signup = async (
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    phone: string,
    token: string,
  ) => {
    const data: any = await custOFetch(`${config.public.apiBase}/auth/judge-signup`, {
      method: 'POST',
      credentials: 'include',
      body: {
        email,
        firstName,
        lastName,
        password,
        phone,
        token,
      },
    })
    console.log('data from signup', data)
    return authStore.user
  }

  const angler_signup = async (
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    phone: string,
    token: string,
  ) => {
    const data: any = await custOFetch(`${config.public.apiBase}/auth/angler-signup`, {
      method: 'POST',
      credentials: 'include',
      body: {
        email,
        firstName,
        lastName,
        password,
        phone,
        token,
      },
    })
    console.log('data from signup', data)
    return authStore.user
  }

  const resetPassword = async (
    email: string,
    newPassword: string,
    newPasswordToken: string,
  ) => {
    const data: any = await $fetch(`${config.public.apiBase}/auth/email/reset-password`, {
      method: 'POST',
      credentials: 'include',
      body: {
        email,
        newPassword,
        newPasswordToken,
      },
    })
    return data
  }

  const requestReset = async (
    email: string,
  ) => {
    const data: any = await $fetch(`${config.public.apiBase}/auth/email/forgot-password/${email}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    })
    return data
  }

  const logout = async () => {
    setUser(null)
    router.push({
      path: '/login',
      query: {
        from: route.fullPath,
      },
    })
  }

  const exists = async (email: string) => {
    try {
      const data: null | 'exists' = await custOFetch(`${config.public.apiBase}/users/exists/${email}`, {
        method: 'GET',
        mode: 'cors',
      })
      return data === 'exists'
    }
    catch (error) {
      captureException(error)
      window.console.log(error)
    }

    return authStore.user
  }

  const me = async () => {
    try {
      console.log('trying me')
      const data: any = await custOFetch(`${config.public.apiBase}/users/me`, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
      })
      console.log('data from me', data)
      await authStore.setUser(data)
      // const user = setUser(data)
      return data
    }
    catch (error) {
      captureException(error)
      window.console.log(error)
    }

    return authStore.user
  }

  return {
    login,
    logout,
    signup,
    exists,
    me,
    verify,
    resetPassword,
    requestReset,
    invite_signup,
    leader_signup,
    judge_signup,
    angler_signup,
  }
}
